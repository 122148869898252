<template>
  <v-container>
    <div class="ml-2"><PageTitle text="Requests" /></div>
    <div class="buttonText ml-1" id="result">
      <v-btn
        v-ripple="{ class: 'primary--text' }"
        color="#04a9f3"
        class="ml-2 mt-3 white--text"
        style="font-size: 12px"
        @click="newOrderDialog"
      >
        New <v-icon size="20" color="white">mdi-plus</v-icon>
      </v-btn>

      <!--      <button class="button ml-2 mt-6" @click.stop="create">-->
      <!--        New <v-icon size="20" color="white">mdi-plus</v-icon>-->
      <!--      </button>-->

      <h4 style="margin-top: 20px; margin-left: 10px" class="blue-grey--text">
        List of Requests
      </h4>
    </div>
    <v-row
      align="center"
      justify="center"
      style="margin-top: 5px; margin-left:1px; margin-right:10px"
    >
      <v-col cols="12" sm="12">
        <template>
          <div>
            <v-data-table
              :headers="headers"
              :items="orders"
              item-key="order"
              :server-items-length="total"
              :options.sync="options"
              :loading="loading"
              loading-text="Loading... Please wait"
              class="elevation-1"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.chequeId.number }}</td>
                  <td>{{ item.branchId.name }}</td>
                  <td>{{ item.productId.name }}</td>
                  <td>{{ item.quantity }}</td>
                  <td>{{ item.chequeId.amount }}</td>
                  <td>{{ item.chequeId.createdAt | format }}</td>
                  <td class="justify-left layout px-0">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          class="mx-1"
                          link
                          :to="{
                            name: 'master.order-details',
                            params: {
                              id: item.id,
                              cheque: item.chequeId.number,
                              branchName: item.branchId.name,
                              product: item.productId.name,
                              amount: item.chequeId.amount
                            }
                          }"
                          dark
                          v-on="on"
                        >
                          <v-icon color="blue-grey">visibility</v-icon>
                        </v-btn>
                      </template>
                      <span>View</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          class="mx-1"
                          tag="a"
                          link
                          @click="openDialog(item)"
                          dark
                          v-on="on"
                        >
                          <v-icon color="blue-grey">mdi-pencil-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          class="mx-0"
                          @click="deleteFunction(item)"
                          dark
                          v-on="on"
                        >
                          <v-icon color="pink" size="19"
                            >mdi-trash-can-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </template>
      </v-col></v-row
    >
    <v-dialog v-model="dialog" width="400px">
      <v-card ref="form">
        <v-card-title>
          <span class="ml-6 subtitle-1 font-weight-bold blue-grey--text"
            >Create Order</span
          >
          <v-spacer></v-spacer>
          <v-progress-linear
            v-show="progress"
            class="mx-6"
            indeterminate
            color="cyan"
          ></v-progress-linear>
        </v-card-title>
        <v-card-actions>
          <v-spacer
            ><v-form class="px-3">
              <v-col>
                <v-row>
                  <v-select
                    item-text="name"
                    item-value="id"
                    :items="branches"
                    dense
                    outlined
                    label="Branch"
                    ref="branch"
                    class="mx-5 px-2 required"
                    v-model="branch"
                    :rules="[rules.requiredBranch]"
                  ></v-select>

                  <v-text-field
                    dense
                    outlined
                    label="Cheque Number"
                    ref="name"
                    class="mx-5 px-2 required"
                    v-model="chequeNumber"
                    :rules="[rules.requiredChec]"
                  >
                  </v-text-field>

                  <v-select
                    item-text="name"
                    item-value="id"
                    :items="products"
                    dense
                    outlined
                    label="Product"
                    ref="product"
                    class="mx-5 px-2 required"
                    v-model="product"
                    :rules="[rules.requiredProduct]"
                  >
                  </v-select>
                </v-row>
                <v-row>
                  <v-text-field
                    dense
                    outlined
                    label="Quantity"
                    ref="quantity"
                    class="mx-5 px-2 required"
                    v-model="quantity"
                    :rules="[rules.requiredQuant]"
                  >
                  </v-text-field>
                  <v-text-field
                    dense
                    outlined
                    label="Amount"
                    ref="amount"
                    class="mx-5 px-2 required"
                    v-model="chequeAmount"
                    :rules="[rules.requiredDes]"
                  >
                  </v-text-field>
                  <!--                  <v-col cols="12" sm="12" md="12" lg="12"-->
                  <!--                    ><v-select-->
                  <!--                      dense-->
                  <!--                      :items="regions"-->
                  <!--                      v-model="region"-->
                  <!--                      ref="region"-->
                  <!--                      label="Region"-->
                  <!--                      outlined-->
                  <!--                      :rules="[rules.requiredRegion]"-->
                  <!--                      class="mx-2 px-2 my-n4 required"-->
                  <!--                    ></v-select-->
                  <!--                  ></v-col>-->

                  <!--                  <v-text-field-->
                  <!--                    dense-->
                  <!--                    outlined-->
                  <!--                    label="Latitude"-->
                  <!--                    class="mx-5 px-2 required"-->
                  <!--                    ref="lat"-->
                  <!--                    v-model="lat"-->
                  <!--                    :rules="[rules.requiredLat]"-->
                  <!--                  >-->
                  <!--                  </v-text-field>-->
                  <!--                  <v-text-field-->
                  <!--                    dense-->
                  <!--                    outlined-->
                  <!--                    label="Longitude"-->
                  <!--                    class="mx-5 px-2 required"-->
                  <!--                    ref="lng"-->
                  <!--                    v-model="lng"-->
                  <!--                    :rules="[rules.requiredLng]"-->
                  <!--                  >-->
                  <!--                  </v-text-field>-->
                </v-row>
              </v-col>
              <v-row>
                <v-spacer />
                <v-btn
                  small
                  class=" px-1 mt-n5 mb-4 ml-10"
                  text
                  @click="dialog = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  small
                  color="blue"
                  class="  px-3 mt-n5 mb-4 ml-5 mr-10 white--text"
                  @click.stop="add"
                >
                  Save
                </v-btn>
              </v-row>
            </v-form></v-spacer
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="text-center">
      <v-dialog v-model="dialogDelete" max-width="290">
        <v-card>
          <v-progress-linear
            v-show="progress"
            indeterminate
            color="cyan"
          ></v-progress-linear>
          <v-card-title class="blue-grey--text">
            <h5>
              Delete this order?
            </h5>
          </v-card-title>

          <v-card-text>
            <h4>{{ orderInfo.chequeId.number }}</h4>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="darken-1" text @click="dialogDelete = false">
              No
            </v-btn>

            <v-btn color="red darken-1" text @click="deleteOrder()">
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <v-dialog v-model="dialogUpdate" width="400px">
      <v-card ref="form">
        <v-card-title>
          <span class="ml-6 subtitle-1 font-weight-bold blue-grey--text"
            >Update Order</span
          >
          <v-spacer></v-spacer>
          <v-progress-linear
            v-show="progress"
            class="mx-6"
            indeterminate
            color="cyan"
          ></v-progress-linear>
        </v-card-title>
        <v-card-actions>
          <v-spacer
            ><v-form class="px-3">
              <v-col>
                <v-row>
                  <v-select
                    :items="products"
                    item-value="id"
                    item-text="name"
                    dense
                    outlined
                    label="Product"
                    ref="itemProduct"
                    class="mx-5 px-2 required"
                    v-model="itemProduct"
                    :rules="[rules.requiredProduct]"
                  >
                  </v-select>
                  <v-text-field
                    dense
                    outlined
                    label="Cheque Number"
                    ref="itemChequeNumber"
                    class="mx-5 px-2 required"
                    v-model="itemChequeNumber"
                    :rules="[rules.requiredChec]"
                  >
                  </v-text-field>
                </v-row>
                <v-row>
                  <v-text-field
                    dense
                    outlined
                    label="Cheque Amount"
                    ref="itemCheckAmount"
                    class="mx-5 px-2 required"
                    v-model="itemChequeAmount"
                    :rules="[rules.requiredAmount]"
                  >
                  </v-text-field>
                  <v-text-field
                    dense
                    outlined
                    label="Quantity"
                    ref="itemQuantity"
                    class="mx-5 px-2 required"
                    v-model="itemQuantity"
                    :rules="[rules.requiredQuant]"
                  >
                  </v-text-field>

                  <!--                  <v-col cols="12" sm="12" md="12" lg="12"-->
                  <!--                    >-->
                  <!--                  <v-select-->
                  <!--                      dense-->
                  <!--                      :items="regions"-->
                  <!--                      v-model="itemRegion"-->
                  <!--                      ref="region"-->
                  <!--                      label="Region"-->
                  <!--                      outlined-->
                  <!--                      :rules="[rules.requiredRegion]"-->
                  <!--                      class="mx-2 px-2 my-n4 required"-->
                  <!--                    ></v-select-->
                  <!--                  ></v-col>-->

                  <!--                                <v-text-field-->
                  <!--                                        dense-->
                  <!--                                        outlined-->
                  <!--                                        label="Phone"-->
                  <!--                                        class="mx-5 px-2 required"-->
                  <!--                                        ref="phone"-->
                  <!--                                        v-model="itemPhone"-->
                  <!--                                        :rules="[rules.min]"-->
                  <!--                                >-->
                  <!--                                </v-text-field>-->
                  <!--                  <v-text-field-->
                  <!--                    dense-->
                  <!--                    outlined-->
                  <!--                    label="Latitude"-->
                  <!--                    class="mx-5 px-2 required"-->
                  <!--                    ref="lat"-->
                  <!--                    v-model="itemLat"-->
                  <!--                    :rules="[rules.requiredLat]"-->
                  <!--                  >-->
                  <!--                  </v-text-field>-->
                  <!--                  <v-text-field-->
                  <!--                    dense-->
                  <!--                    outlined-->
                  <!--                    label="Longitude"-->
                  <!--                    class="mx-5 px-2 required"-->
                  <!--                    ref="lng"-->
                  <!--                    v-model="itemLng"-->
                  <!--                    :rules="[rules.requiredLng]"-->
                  <!--                  >-->
                  <!--                  </v-text-field>-->
                </v-row>
                <v-row>
                  <v-select
                    :items="branches"
                    item-value="id"
                    item-text="name"
                    dense
                    outlined
                    label="Branch"
                    ref="itemBranch"
                    class="mx-5 px-2 required"
                    v-model="itemBranch"
                    :rules="[rules.requiredBranch]"
                  ></v-select>
                </v-row>
              </v-col>
              <v-row>
                <v-spacer />
                <v-btn
                  small
                  class=" px-1 mt-n5 mb-4 ml-10"
                  text
                  @click="dialogUpdate = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  small
                  class="blue  px-3 mt-n5 mb-4 ml-5 mr-10 white--text"
                  text
                  @click.stop="save"
                >
                  Save
                </v-btn>
              </v-row>
            </v-form></v-spacer
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="showSnackBar" :timeout="4000" top :color="status">
      <span class="white--text">{{ message }}</span>
      <v-btn text color="white" @click="showSnackBar = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import PageTitle from "../../components/PageTitle";
import * as axios from "axios";
import moment from "moment";
export default {
  name: "Orders",
  components: { PageTitle },
  data() {
    return {
      progress: false,
      dialog: false,
      formHasErrors: false,
      showSnackBar: false,
      dialogUpdate: false,
      description: "",
      products: [],
      branches: [],
      phone: "",
      lng: "",
      lat: "",
      message: "",
      status: "",
      product: "",
      chequeNumber: "",
      chequeAmount: 0,
      branch: "",
      quantity: "",
      itemId: "",
      itemChequeNumber: "",
      itemChequeAmount: "",
      itemQuantity: "",
      itemProduct: "",
      itemBranch: "",
      orders: [],
      itemObjects: {
        chequeAmount: null,
        product: null,
        branch: null,
        chequeNumber: null,
        quantity: 0
      },

      dialogDelete: false,
      region: "",
      location: "",
      orderInfo: {
        chequeId: {
          number: ""
        }
      },
      rules: {
        min: value => value.length >= 10 || "Must be 10 digits",
        requiredChec: value =>
          !!value || value === null || "Cheque Number is required.",
        requiredAmount: value =>
          !!value || value === null || "Amount is Required.",
        requiredProduct: value =>
          !!value || value === null || "Product is Required.",
        requiredQuant: value =>
          !!value || value === null || "Quantity is Required.",
        requiredBranch: value =>
          !!value || value === null || "Branch is Required."
      },
      loading: true,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 5
      }
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Cheque Number",
          align: "start",
          sortable: false,
          value: "chequeNumber"
        },
        { text: "Branch Name", value: "branchId" },
        { text: "Product Name", value: "name" },
        { text: "Quantity", value: "quantity" },
        { text: "Cheque Amount", value: "chequeAmount" },
        { text: "Date", value: "createdAt" },

        { text: "Actions", value: "" }
      ];
    }
  },
  filters: {
    format(value) {
      return moment(value)
        .subtract(0, "days")
        .calendar();
    }
  },

  watch: {
    options: {
      handler(value) {
        this.getOrders(value.page, value.itemsPerPage);
      },
      deep: true
    }
  },

  created() {
    this.getOrders(this.options.page, this.options.itemsPerPage);
  },

  methods: {
    openDialog(value) {
      this.dialogUpdate = true;
      this.itemId = value.id;
      this.itemChequeAmount = value.chequeId.amount;
      this.itemChequeNumber = value.chequeId.number;
      this.itemBranch = value.branchId.name;
      this.itemQuantity = value.quantity;
      this.itemProduct = value.productId.id;
      this.getProducts();
      this.getBranches();
    },
    getProducts() {
      axios({
        method: "get",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`
        },
        url: `${process.env.VUE_APP_BASE}/products`
      })
        .then(response => {
          //console.log(response);
          if (response.status === 200) {
            this.products = response.data;
          }
        })
        .catch(err => {
          if (err.statusCode === 401) {
            this.$router.replace({ path: "/login" });
          }
        });
    },

    newOrderDialog() {
      this.dialog = true;
      this.getProducts();
      this.getBranches();
    },
    deleteFunction(item) {
      this.dialogDelete = true;
      this.orderInfo = item;
    },

    deleteOrder() {
      this.progress = true;
      setTimeout(() => {
        axios({
          method: "delete",
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`
          },
          url: `${process.env.VUE_APP_BASE}/orders/${this.orderInfo.id}`
        })
          .then(response => {
            this.dialogDelete = false;
            this.progress = false;
            if (response.status === 200) {
              this.status = "success";
              this.message = "Order has been deleted successfully.";
              this.showSnackBar = true;
              this.dialogUpdate = false;
              this.getOrders(this.options.page, this.options.itemsPerPage);
            }
          })
          .catch(err => {
            if (err.response.status === 401) {
              this.$router.replace({ path: "/login" });
            } else {
              //console.log(err);
            }
          });
      }, 2000);
    },

    getBranches() {
      axios({
        method: "get",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`
        },
        url: `${process.env.VUE_APP_BASE}/branches`
      })
        .then(response => {
          //console.log(response);
          if (response.status === 200) {
            this.branches = response.data;
          }
        })
        .catch(err => {
          this.loading = false;
          if (err.statusCode === 401) {
            this.$router.replace({ path: "/login" });
          }
        });
    },

    save() {
      this.progress = true;
      //console.log(this.itemId);
      setTimeout(() => {
        axios({
          method: "put",
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`
          },
          url: `${process.env.VUE_APP_BASE}/orders/${this.itemId}`,
          data: {
            chequeAmount: this.itemChequeAmount,
            chequeNumber: this.itemChequeNumber,
            branchId: this.itemBranch,
            quantity: this.itemQuantity,
            productId: this.itemProduct
          }
        })
          .then(response => {
            //console.log(response);
            this.progress = false;
            if (response.status === 200) {
              this.status = "success";
              this.message = "Order has been updated successfully.";
              this.showSnackBar = true;
              this.dialogUpdate = false;
              this.getOrders(this.options.page, this.options.itemsPerPage);
            }
          })
          .catch(err => {
            if (err.status === 401) {
              this.$router.replace({ path: "/login" });
            } else {
              this.progress = false;
              this.status = "warning";
              this.showSnackBar = true;
              this.message = "Failed to update this order.";
            }
          });
      }, 5000);
    },

    add() {
      if (
        this.branch !== "" &&
        this.chequeNumber !== "" &&
        this.chequeAmount !== "" &&
        this.quantity !== "" &&
        this.product !== ""
      ) {
        this.progress = true;
        setTimeout(() => {
          axios({
            method: "post",
            headers: {
              authorization: `Bearer ${localStorage.getItem("token")}`
            },
            url: `${process.env.VUE_APP_BASE}/orders`,
            data: {
              chequeAmount: this.chequeAmount,
              productId: this.product,
              branchId: this.branch,
              chequeNumber: this.chequeNumber,
              quantity: this.quantity
            }
          })
            .then(response => {
              this.getOrders(this.options.page, this.options.itemsPerPage);
              this.dialog = false;
              this.progress = false;
              if (response.status === 201) {
                this.status = "success";
                this.message = response.data.message;
                this.showSnackBar = true;
              }
              this.chequeAmount = this.itemObjects.chequeAmount;
              this.branch = this.itemObject.branch;
              this.product = this.itemObjects.product;
              this.quantity = this.itemObjects.quantity;
              this.chequeNumber = this.itemObjects.chequeNumber;
            })
            .catch(err => {
              if (err.status === 401) {
                this.$router.replace({ path: "/login" });
              } else {
                this.progress = false;
                this.dialog = true;
                this.status = "warning";
                this.message = "Failed to add new order.";
                //console.log(err);
              }
            });
        }, 1000);
      } else {
        this.showSnackBar = true;
        this.status = "warning";
        this.message = "All fields required.";
      }
    },

    getOrders(page, itemsPerPage) {
      this.progress = true;
      axios({
        method: "get",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`
        },
        url: `${process.env.VUE_APP_BASE}/orders?paginated=true&page=${page}&limit=${itemsPerPage}`
      })
        .then(response => {
          //console.log(response);
          if (response.status === 200) {
            this.progress = false;
            this.loading = false;
            this.orders = response.data.paginateObj.docs;
            this.options.page = response.data.paginateObj.page;
            this.options.itemsPerPage = parseInt(
              response.data.paginateObj.limit
            );
            this.total = response.data.paginateObj.total;
          }
        })
        .catch(err => {
          this.loading = false;
          this.progress = false;
          if (err.statusCode === 401) {
            this.$router.replace({ path: "/login" });
          }
        });
    }
  }
};
</script>

<style scoped>
.button {
  background-color: #04a9f3; /* Green */
  border: none;
  color: white;
  padding: 1px 4px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-right: 40px;
  cursor: pointer;
}
#result .v-btn {
  min-width: 65px;
  width: 40px;
}
/* removes grey hover effect */
#result .v-btn::before {
  background-color: transparent;
}

/* adds icon scale hover effect */
#result .v-btn i:hover {
  transform: scale(1.15);
}

.buttonText {
  display: flex;
  flex-direction: row;
}
</style>
